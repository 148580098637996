<template>
  <button class="btn btn-primary" :disabled="loading">
    <img
      :src="require('@/assets/icons/loading.svg')"
      alt=""
      v-if="loading"
      width="15"
    />
    <span v-else>
      <slot></slot>
    </span>
  </button>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    loading: Boolean,
  },
})
export default class ButtonValidationComponent extends Vue {}
</script>

<style lang="scss">
.td-footer-info {
  background: #0062ff;
  border-radius: 30px;
  padding: 2px 8px;
  color: white;
  display: inline-block;
}
</style>
