<template>
  <a-form-item
    :ref="ref"
    :label="label"
    :name="name"
    class="form-group"
    :class="{ populated: this.value !== '', focused: isFocus }"
  >
    <a-input
      :type="htmlType || 'text'"
      v-model:value="value"
      @input="handleChange"
      @focus="handleFocus"
      @blur="handleBlur"
      class="form-control"
      :readonly="readonly"
    />
  </a-form-item>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Form from 'ant-design-vue/lib/form'
import Input from 'ant-design-vue/lib/input'

@Options({
  props: {
    ref: String,
    label: String,
    name: String,
    modelValue: String,
    readonly: Boolean,
    blur: Function,
    focus: Function,
    input: Function,
    htmlType: String,
  },
  components: {
    AFormItem: Form.Item,
    AInput: Input,
  },
  emits: ['update:modelValue', 'focus', 'blur'],
  watch: {
    modelValue(value) {
      this.value = value
      this.$emit('update:modelValue', value)
    },
  },
  data() {
    return {
      value: this.modelValue || '',
      isFocus: false,
    }
  },
  methods: {
    handleChange() {
      this.$emit('update:modelValue', this.value)
      this.input && this.input()
    },
    handleFocus() {
      this.isFocus = true
      this.$emit('focus')
    },
    handleBlur() {
      this.isFocus = false
      this.$emit('blur')
    },
  },
})
export default class FloatingFormItem extends Vue {}
</script>

<style lang="scss">
.form-group {
  margin-bottom: 20px;
  .ant-form-item-label {
    position: absolute;
    text-align: left;
    top: 50%;
    transform: translateY(-50%);
    label {
      position: relative;
      top: 0;
    }
  }
  &.focused,
  &.populated {
    .ant-form-item-label {
      label {
        top: -4px;
      }
    }
  }
  .ant-form-explain {
    font-size: 12px;
    margin-top: 3px;
  }
  .ant-form-item-control {
    height: 50px;
    &.has-error {
      .ant-form-explain {
        position: absolute;
        margin: 0;
        bottom: -23px;
      }
    }
  }
}
</style>
