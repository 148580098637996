
import { Options, Vue } from 'vue-class-component'
import Form from 'ant-design-vue/lib/form'
import Input from 'ant-design-vue/lib/input'

@Options({
  props: {
    ref: String,
    label: String,
    name: String,
    modelValue: String,
    readonly: Boolean,
    blur: Function,
    focus: Function,
    input: Function,
    htmlType: String,
  },
  components: {
    AFormItem: Form.Item,
    AInput: Input,
  },
  emits: ['update:modelValue', 'focus', 'blur'],
  watch: {
    modelValue(value) {
      this.value = value
      this.$emit('update:modelValue', value)
    },
  },
  data() {
    return {
      value: this.modelValue || '',
      isFocus: false,
    }
  },
  methods: {
    handleChange() {
      this.$emit('update:modelValue', this.value)
      this.input && this.input()
    },
    handleFocus() {
      this.isFocus = true
      this.$emit('focus')
    },
    handleBlur() {
      this.isFocus = false
      this.$emit('blur')
    },
  },
})
export default class FloatingFormItem extends Vue {}
